import React, { Component } from 'react';
import Account from '../Component/AccountSection';
import Modal from '../Component/Modal';
import TableAccount from '../Component/Table';
import {
    validateReceiptQuantity,
    validateDoc,
    validateNames,
    validateApP,
    showError,
    validateApM,
    validateSecondName,
    validateAccountQuantity,
} from '../Component/Validation/validate';
import LoadingSpiner from '../Component/LoadingSpinner';
import PopUpMessage from '../Component/PopUpMessage';
import services from '../services/report.service';
import SalesReport from '../Reports/SalesReport';
import { Navigate } from "react-router-dom";
import "../Styles/fadePane.css";
import "../Styles/Sales.css";

// Tiempo en milisegundos del ultimo intento de registro de movimiento
let lastRegister = 0

/** Sales
 * Retorna la el formulario para realizar las ventas de los valorados
 */
class Sales extends Component {

    constructor(props) {
        super(props);
        this.state = {
            titleModal: "Cuentas",
            assocAccount: [],
            accounts: [],
            addAccounts: [],
            showModal: false,
            modalInsertar: false,
            busqueda: "",
            textInputName: null,
            backAccount: {},
            selectRowAccount: { id: "", codigo: "", nombre: "", unidad: "", nro: 1000001, tipo_monto: "", precio: 0 },
            prePrint: 1000001,
            selectRowPerson: { documento: "", sis: "", nombre_1: "", nombre_2: "", paterno: "", materno: "", complemento: "", fecha_nacimiento: "" },
            sispay: "",
            cipay: "",
            cantCustodia: 1,
            totalCustodia: "",
            disabled: false,
            disabledPerson: false,
            cantResiv: 1,
            observations: '',
            registring: true,
            typeSearch: '',
            showNotPay: false,
            detailSisPay: [],
            loading: false,
            spinner: false,
            stockAccount: 0,
            adminReport: false,
            rolReport: false,
            dticRole: false,
            shiftActive: false,
            popUpMessage: '',
            popUpMessageVisible: false,
            closeMoviments: false,
            closeType: 0,
        };

        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleChangeAccount = this.handleChangeAccount.bind(this);
        this.handleChangePerson = this.handleChangePerson.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.setBusquedaData = this.setBusquedaData.bind(this);
        this.setAccountData = this.setAccountData.bind(this);
        this.showInputPerson = this.showInputPerson.bind(this);
        this.showInputAccount = this.showInputAccount.bind(this);
        this.getListAssocAccounts = this.getListAssocAccounts.bind(this);
        this.updatePreprint = this.updatePreprint.bind(this);
        this.changePreprint = this.changePreprint.bind(this);

        this.totalCantidad = this.totalCantidad.bind(this);
        this.totalCustodia = this.totalCustodia.bind(this);
        this.searchAccount = this.searchAccount.bind(this);
        this.searchPerson = this.searchPerson.bind(this);
        this.searchAddAccount = this.searchAddAccount.bind(this);
        this.searchDebtsDetails = this.searchDebtsDetails.bind(this);

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.closePopUp = this.closePopUp.bind(this);

        this.setFocus = this.setFocus.bind(this)
        this.registerMoviment = this.registerMoviment.bind(this)
        this.loadPage = this.loadPage.bind(this)
        this.printReport = this.printReport.bind(this)
        this.moveFocus = this.moveFocus.bind(this)

        this.refSearch = React.createRef(null);
        this.refDoc = React.createRef(null);
        this.refDate = React.createRef(null);
        this.refApellido1 = React.createRef(null);
        this.refApellido2 = React.createRef(null);
        this.refNombre1 = React.createRef(null);
        this.refNombre2 = React.createRef(null);
        this.refCod = React.createRef(null);
        this.refCust = React.createRef(null);
        this.refObs = React.createRef(null)
        this.refBtnFinish = React.createRef(null)
        this.refCantCust = React.createRef(null)
    }

    componentDidMount() {
        const actualDate = new Date()
        this.loadPage()
        this.setState({ spinner: true })
        this.updatePreprint()
        services.isClosureMovement(actualDate).then(async (data) => {
            if (data.isOk) {
                this.setState({ closeMoviments: true })
                this.setState({ closeType: data.closeType });
            }
        });
        this.refDoc.current.focus();
    }

    updatePreprint = () => {
        services.getPreprinted().then((data) => {
            if (data.isOk) {
                this.setState({ prePrint: data.preprinted })
                this.setState({ spinner: false })
            }
        })
    }

    changePreprint = (value) => {
        value = value < 0 ? 0 : value;
        this.setState({ prePrint: value })
    }

    async loadPage() {
        await services.accountPlanRole().then((data) => {
            this.setState({ adminReport: data.adminReport })
            this.setState({ rolReport: data.report })
            this.setState({ dticRole: data.dtic })
        })
    }

    saveCurrentAccount = (data) => {
        localStorage.setItem("key.account", JSON.stringify(data));
    }

    getBackAccount = async () => {
        const storedAccount = JSON.parse(localStorage.getItem("key.account"))
        if (storedAccount) {
            this.setState({
                selectRowPerson: storedAccount,
                registring: false,
                disabledPerson: true
            })
            this.cleanErrors();
            this.refCod.current.focus();
        }
    }
    //Consulta y obtiene la lista de las cuentas asociadas a una cuenta principal seleccionada previamente
    getListAssocAccounts = async (cod) => {
        //Consulta, envia el codigo de la cuenta en cuestion
        await services.getAsociateAccounts(cod)
            //cambia el valor de assocAccount con la lista obtenida
            .then(res => this.setState({ assocAccount: [...res] }))
            .catch(err => {
                //en caso de error se cambia el valor de assocAccount a una lista vacia
                this.setState({ assocAccount: [] })
                console.log('ocurrio un error', err)
            })
    }

    searchAccount = async (busqueda, tipo, typeSearch) => {
        await services.postAccounts(busqueda, tipo, false)
            .then((data) => {
                if (data === null) {
                    this.setState({ accounts: [] });
                } else {
                    this.setState({ accounts: data });
                    showError(true, tipo);
                    if (data.length === 1 && typeSearch) {
                        this.showInputAccount(data[0]);
                        this.getListAssocAccounts(data[0].id);
                    }
                    else if (typeSearch) this.handleShowModal('Cuentas');
                }
            })
            .catch(err => console.log('ocurrio un error', err))
    }

    searchAddAccount = async (busqueda, tipo) => {
        await services.postAccounts(busqueda, tipo, true)
            .then((data) => {
                if (data === null) {
                    this.setState({ accounts: [] });
                } else {
                    const right = []
                    for (var i = 0; i < data.length; i++) {
                        var assAcc = false;
                        if (data[i].codigo === this.state.selectRowAccount.codigo) assAcc = true;

                        for (var j = 0; j < this.state.assocAccount.length; j++) {
                            if (this.state.assocAccount[j].codigo === data[i].codigo) {
                                assAcc = true;
                                break;
                            }
                        }
                        if (!assAcc && data[i].tipo_monto === 'F')
                            right.push(data[i]);
                    }
                    this.setState({ accounts: right });
                }
            })
            .catch(err => console.log('ocurrio un error', err))
    }

    searchPerson = async (busqueda, tipo, typeSearch) => {
        if (busqueda.length > 0) {
            await services.postPersons(busqueda, tipo)
                .then((data) => {
                    if (data === null || data === '22P02') {
                        this.setState({ accounts: [], registring: true });
                        if (typeSearch) this.nextInputFocus(busqueda, tipo);
                    } else {
                        this.setState({ accounts: data, registring: false });
                        if (data.length === 1 && typeSearch) {
                            let refactor = this.refactorData(data[0]);
                            this.showInputPerson(refactor);
                        }
                        else if (typeSearch) this.handleShowModal('Personas');
                    }
                })
                .catch(err => console.log('ocurrio un error', err))
        }
    }

    refactorData = (person) => {
        let nameSeparation = person.nombres.split(' ')
        return {
            ...person,
            nombre_1: nameSeparation[0],
            nombre_2: nameSeparation[2] ? nameSeparation.slice(1).join(' ') : nameSeparation[1],
            sis: person.codigo,
        }
    }

    searchDebts = async (busqueda, tipo) => {
        await services.postDebts(busqueda, tipo)
            .then(async (data) => {
                if (data === null) {
                    this.setState({ accounts: [], showNotPay: true });
                } else {
                    let newdata = await Promise.all(data.map(async (element) => {
                        element.total = await this.precioTotal(element)
                        element.subtotal = this.state.detailSisPay.reduce((_subtotal, element) => {
                            _subtotal += parseFloat(element.precio) * parseFloat(element.cantidad);
                            return _subtotal
                        }, 0);
                        return element
                    }))
                    this.setState({ accounts: newdata });
                }
            })
            .catch(err => console.log('ocurrio un error', err))
    }

    searchDebtsDetails = async (busqueda) => {
        await services.postDebtsDetails(busqueda.id)
            .then((data) => {
                if (data === null)
                    this.setState({ detailSisPay: [] });
                else
                    this.setState({ detailSisPay: data });
            })
            .catch(err => console.log('ocurrio un error', err))
    }

    /**
     * Metodo que manda a generar el recibo de la venta de un valor
     *
     * @param string date
     * @param int nroRecibos
     */
    printReport = (date, nroRecibos) => {
        const outputDate = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getFullYear().toString().substr(-2) + ' ' + date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0')
        const report = new SalesReport();
        const item = [
            {
                concept: `${this.state.selectRowAccount.unidad} — ${this.state.selectRowAccount.nombre}`,
                cost: parseFloat(this.state.totalCustodia)
            }
        ]

        if (this.state.assocAccount.length > 0) {
            this.state.assocAccount.map((data) => {
                item.push({
                    concept: `${data.unidad} — ${data.cuenta_asociada}`,
                    cost: (data.cantidad * parseFloat(data.precio))
                })
                return null;
            })
        }

        const documento = this.state.selectRowPerson.complemento
            ? `${this.state.selectRowPerson.documento}-${this.state.selectRowPerson.complemento}`
            : this.state.selectRowPerson.documento

        report.generateReport(
            nroRecibos,
            "",
            `${this.state.selectRowPerson.paterno} ${this.state.selectRowPerson.materno} ${this.state.selectRowPerson.nombre_1} ${this.state.selectRowPerson.nombre_2}`,
            item,
            this.state.observations,
            documento,
            this.state.selectRowPerson.sis,
            outputDate,
            parseInt(this.state.cantResiv)
        )
        this.cleanForm(); // Aun es sis por como se manejan algunos campos en el formulario   ^^^^^
    }

    precioTotal = async (data) => {
        await this.searchDebtsDetails(data);
        var total = parseFloat(data.precio) * parseFloat(data.cantidad);
        this.state.detailSisPay.map((element) => {
            total += parseFloat(element.precio) * parseFloat(element.cantidad);
            return null;
        });
        return total;
    }

    handleAnswerChange = (e) => {
        if (e.key === 'Enter') {
            const inputName = e.target.name
            const inputId = e.target.id
            const inputValue = e.target.value

            this.setState({ accounts: [] })
            this.setState({ busqueda: inputValue });

            if (inputName === "inputAccount") {
                this.searchAccount(inputValue, inputId, true);
            } else if (inputName === "inputPerson") {
                showError(true, 'paterno')
                showError(true, 'nombre_1')
                this.searchPerson(inputValue.replace(/\s+/g, ''), inputId, true);
                this.setState({ typeSearch: inputId })
            } else if (inputName === "inputPayLine" && inputValue !== "") {
                this.searchDebts(inputValue, inputId, true);
            } else if (inputName === '') {
                this.setState({ assocAccount: [] })
            }
            
            this.setState({ textInputName: inputName });
            this.moveFocus(e)
        }
    }

    /** Mueve el focus de entre inputs con la tecla enter */
    moveFocus(e) {
        if (e.key === 'Enter') {
            const {shiftKey} = e
            const id = e.target.id
            if (id==='fecha_nacimiento') {
                shiftKey ? this.refDoc?.current?.focus() : this.refApellido1?.current?.focus()
            } else if (id==='paterno') {
                shiftKey ? this.refDate?.current?.focus() : this.refApellido2?.current?.focus()
            } else if (id==='materno') {
                shiftKey ? this.refApellido1?.current?.focus() : this.refNombre1?.current?.focus()
            } else if (id==='nombre_1') {
                shiftKey ? this.refApellido2?.current?.focus() : this.refNombre2?.current?.focus()
            }else if (id==='nombre_2') {
                shiftKey ? this.refNombre1?.current?.focus() : this.refCod?.current?.focus()
            }
        }
    }

    setFocus = (e) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'inputCustodia') {
                if (this.state.shiftActive) {
                    this.refCantCust.current.focus()
                } else {
                    this.refBtnFinish.current.focus()
                }
            }
            if (e.target.name === 'cantCust' || e.target.name === 'inputObs') {
                this.refBtnFinish.current.focus()
            }
        }
        this.setState({ shiftActive: false })
    }

    handleKeyPress = (e) => {
        if (e.code === 'KeyW') {
            if (e.target.id === 'btnTerminar') {
                this.refObs.current.focus()
            }
        }
    }

    updatePrice = (e) => {
        /**
         * Aqui realizamos la validacion del numero para evitar el problema de las configuraciones regocionales del sistema opreativo
         */
        let nuevoValor = e.target.value.replace(/[^0-9,.]/g, "").replace(",", ".");
        let dotIndex = nuevoValor.indexOf('.');
        if (dotIndex !== -1 && nuevoValor.length > dotIndex + 3) {
            nuevoValor = nuevoValor.slice(0, dotIndex + 3);
        }
        e.target.value = (nuevoValor)
        this.totalCustodia(e.target.value, true)
    }


    handleKeyDown = (e) => {
        if (e.key === 'Shift') {
            this.setState({ shiftActive: true })
        }
    }
    handleKeyUp = (e) => {
        if (e.key === 'Shift') {
            this.setState({ shiftActive: false })
        }
    }

    handleChangeAccount = (e) => {
        this.setState({
            selectRowAccount: {
                ...this.state.selectRowAccount,
                [e.target.id]: e.target.value,
            },
        });
        showError(true, 'codigo');
        if (e.target.id === 'codigo' && e.target.value !== this.state.selectRowAccount.codigo) {
            this.inputCodeEmpty(e.target.value);
        }

    };

    getlength = (number) => {
        return number.toString().length;
    }

    handleChangePerson = (e) => {
        if (e.target.value.length > 10 && (e.target.id === 'sis')) {
            if (e.target.id === 'sis') showError(false, 'sis')
        } else {
            if (e.target.id === 'nombre_1') showError(true, 'nombre_1')
            if (e.target.id === 'paterno' || e.target.id === 'materno') {
                showError(true, 'paterno')
                this.setState({
                    selectRowPerson: {
                        ...this.state.selectRowPerson,
                        [e.target.id]: e.target.value.toUpperCase(),
                    },
                });
            } else {
                if (e.target.id === 'sis') {
                    if ((/^[0-9]+$/g.test(e.target.value)) || e.target.value === '') {
                        this.setState({
                            selectRowPerson: {
                                ...this.state.selectRowPerson,
                                sis: e.target.value === '' ? '' : Number(e.target.value), // En caso rompa algo por no ser un numero
                                // evita que se haga 0 cuando se quiere dejar vacio
                            },
                        });
                    }
                } else {
                    this.setState({
                        selectRowPerson: {
                            ...this.state.selectRowPerson,
                            [e.target.id]: e.target.value.toUpperCase(),
                        },
                    });
                }
            }
            if (e.target.id === 'documento') showError(true, 'documento')
            if (e.target.id === 'sis') showError(true, 'sis')
        }
        if ((e.target.id === 'documento' && e.target.value !== this.state.selectRowPerson.documento) ||
            (e.target.id === 'sis' && e.target.value !== this.state.selectRowPerson.sis)) {
            this.inputDocSisEmpty(e.target.value, e.target.id);
        }
    };

    handleChangePay = (e) => {
        let [sisValue, ciValue] = e.target.id === 'sispay' ? [e.target.value, ''] : ['', e.target.value];
        this.setState({
            sispay: sisValue,
            cipay: ciValue,
            accounts: [],
            showNotPay: false,
        });
    }

    //calcula y actualiza el valor del total del campo cantidad
    totalCantidad = (e) => {
        this.setState({ cantCustodia: e.target.value });
        let result = parseFloat(this.state.selectRowAccount.precio) * e.target.value;

        this.setState({ totalCustodia: parseFloat(result).toFixed(2) });
        showError(validateAccountQuantity(e.target.value) ,'cantCust')
    };

    totalCustodia = (dato, tipo, account) => {
        dato = dato < 0 || dato === '' ? 0 : dato;
        if (dato === 0) {
            setTimeout(() => { this.refCust.current.focus() }, 50);
            setTimeout(() => { this.refCust.current.select() }, 60);
        }
        this.setState({
            selectRowAccount: {
                ...(tipo ? this.state.selectRowAccount : account),
                precio: dato,
            }
        })
        showError(dato !== 0, 'custodia');
        let result = parseFloat(dato) * this.state.cantCustodia;
        this.setState({ totalCustodia: parseFloat(result).toFixed(2) });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleShowModal = (title) => {
        this.setState({ showModal: true, titleModal: title }, () => {
            setTimeout(() => { this.refSearch.current && this.refSearch.current.focus() }, 1)
        });
    }

    handleModal = () => {
        if (!this.state.selectRowAccount.valorado) {
            if (this.state.assocAccount.length < 8) {
                this.setState({
                    showModal: !this.state.showModal,
                    titleModal: "Cuentas",
                    textInputName: "extra",
                    busqueda: ""
                }, () => {
                    setTimeout(() => { this.refSearch.current && this.refSearch.current.focus() }, 1)
                })
                this.searchAddAccount("", 'codigo');
            } else {
                alert("Ya se adiciono el numero máximo de cuentas permitidas.")
            }

        } else {
            alert("La cuenta seleccionada es un valorado, no se pueden adicionar mas cuentas")
        }
    }

    setBusquedaData = (event) => {
        this.setState({ busqueda: event.target.value });
    }

    setAccountData = (dato) => {
        this.setState({ accounts: dato });
    }

    showInputAccount = (dato) => {
        this.setState({ selectRowAccount: dato });
        this.totalCustodia(dato.precio, false, dato);
        showError(true, 'unidad');
        showError(true, 'nombre');
        if (dato.tipo_monto === 'F') {
            this.setState({ disabled: true })
            if (dato.valorado) {
                setTimeout(() => { this.refCantCust.current.focus() }, 100)
            } else {
                setTimeout(() => { this.refBtnFinish.current.focus() }, 100)
            }
        } else {
            this.setState({ disabled: false })
            setTimeout(() => { this.refCust.current.focus() }, 100)
            setTimeout(() => { this.refCust.current.select() }, 110)
        }
        this.handleCloseModal();
    }

    showInputPerson = (dato) => {
        this.setState({
            selectRowPerson: dato,
            disabledPerson: true
        });
        setTimeout(() => {
            this.refCod.current.focus();
        }, 10);
        this.handleCloseModal();
    }

    inputCodeEmpty = (code) => {
        this.setState({
            selectRowAccount: { codigo: code, nombre: "", unidad: "", nro: 0, tipo_monto: "", precio: "" },
            assocAccount: [],
            disabled: false,
            totalCustodia: "",
            cantCustodia: 1
        })
    }

    inputDocSisEmpty = (code, input) => {
        if (input === 'documento' && !this.state.registring)
            this.setState({ selectRowPerson: { documento: code, sis: "", nombre_1: "", nombre_2: "", paterno: "", materno: "", complemento: "", fecha_nacimiento: "" }, registring: true })
        if (input === 'sis' && !this.state.registring)
            this.setState({ selectRowPerson: { documento: "", sis: code, nombre_1: "", nombre_2: "", paterno: "", materno: "", complemento: "", fecha_nacimiento: "" }, registring: true })

        this.setState({ disabledPerson: false })
    }
    //aniade una cuenta externa como asociada a la principal
    addAssocAccounts = (data) => {
        //actualiza la lista assocAccount con el nuevo item
        this.setState({ assocAccount: [...this.state.assocAccount, data] })
    }

    nextInputFocus = (busqueda, tipo) => {
        switch (tipo) {
            case 'documento':
                if (busqueda === "") this.docInput.focus();
                else this.refDate?.current?.focus();
                break;
            case 'sis':
                if (this.docInput.value === "") this.docInput.focus();
                if (busqueda === "") this.sisInput.focus();
                else this.patInput.focus();
                break;
            default:
                break;
        }
    }
    //Actualiza el valor del campo Cantidad de Recibos
    handleChange = (e) => {
        //verifica si el valor ingresado es menor o igual a 2 digitos
        if (e.target.value.length <= 2) {
            //actualiza el valor de cantReciv
            this.setState({ cantResiv: e.target.value })
            //funcion que valida el valor ingresado
            this.validateCant(e.target.value, 'cantReciv')
        }
    }

    //funcion que verifica si una cantidad es valida
    //recive el valor y el id del campo
    validateCant = (value, element) => {
        let val = validateReceiptQuantity(value)
        //verifica si el resultado de la funcion anterior es true (cantidad valida) y si su longitud es mayor a 2
        if (val && element === 'cantReciv' && value.length > 2) {
            showError(false, element)
        } else {
            showError(val, element)
        }
    }

    //verifica si los valores ingresados en la seccion de cuenta son validos
    validateAccount = () => {
        if (this.state.selectRowAccount.codigo === '') {
            showError(false, 'codigo')
        }
        if (this.state.selectRowAccount.nombre === '') {
            showError(false, 'nombre')
            showError(false, 'unidad')
        }
        if (this.state.selectRowAccount.precio === '' || Number(this.state.selectRowAccount.precio) === 0) {
            showError(false, 'custodia')
        }
        this.validateCant(this.state.cantResiv, 'cantReciv')
    }

    validatePerson = () => {
        if (!this.state.selectRowAccount.valorado) {
            showError(validateDoc(this.state.selectRowPerson.documento), 'documento');
            //showError(validateCodSis(this.state.selectRowPerson.sis), 'sis');
            //showError(validateComp(this.state.selectRowPerson.complemento), 'complemento');
            showError(validateApP(this.state.selectRowPerson.paterno), 'paterno');
            showError(validateApM(this.state.selectRowPerson.materno), 'materno');
            showError(validateNames(this.state.selectRowPerson.nombre_1), 'nombre_1');
            showError(validateSecondName(this.state.selectRowPerson.nombre_2), 'nombre_2');
        } else {
            showError(true, 'documento');
            //showError(true, 'sis');
            showError(true, 'complemento');
            showError(true, 'paterno');
            showError(true, 'materno');
            showError(true, 'nombre_1');
            showError(true, 'nombre_2');
        }
    }


    handleObservations = e => {
        this.setState({ observations: e.target.value })
    }

    submit = async () => {
        this.setState({ loading: true })
        const now = new Date().getTime()
        // Verificar que exista un intervalo de 1 segundo entre ejecuciónes de la función
        if (lastRegister === 0 || now - lastRegister >= 1000) {
            lastRegister = now
            //verifica todos los campo asociados a la cuenta
            this.validateAccount()
            //verifica todos los campos asociados a la persona
            this.validatePerson()
            //obtiene todos los campos con errores
            let errors = document.getElementsByClassName('is-invalid')
            //verifica si la cantidad de errores es mayor a O
            if (errors.length > 0) {
                this.setState({ popUpMessage: 'Existen campos incorrectos.', popUpMessageVisible: true, loading: false })
            } else {
                var actualDate = new Date()
    
                await services.isClosureMovement(actualDate).then(async (data) => {
                    if (data.isOk) {
                        this.setState({ loading: false, popUpMessageVisible: true, popUpMessage: data.message })
                    } else {
                        if (this.state.selectRowAccount.valorado) {
    
                            await services.getStockValuedAccount(this.state.selectRowAccount.id)
                                .then((data) => {
                                    if (data.result === 0) {
                                        alert(`No se pudo registrar el movimiento, el stock actual para la cuenta ${this.state.selectRowAccount.nombre} es de 0.`)
                                        this.setState({ loading: false })
                                        this.cleanForm()
                                        return
                                    }
    
                                    if (data.result < this.state.cantCustodia) {
                                        alert(`No se pudo registrar el movimiento, el stock actual para la cuenta ${this.state.selectRowAccount.nombre} es de ${data.result}`)
                                        this.setState({ loading: false })
                                        this.cleanForm()
                                        return
                                    }
                                    this.registerMoviment()
                                })
                        } else {
                            this.registerMoviment()
                        }
                        setTimeout(() => {
                            this.refDoc.current.focus();
                        }, 3000); // Tal vez se tenga que ajustar
                    }
                })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    /**
     * Metodo que registra la venta de valores y manda a generar el recibo
     *
     */
    async registerMoviment() {

        var personId = 0;

        if (!this.state.selectRowAccount.valorado) {

            let data = {
                documento: this.state.selectRowPerson.documento?.replace(/\s+/g, ''),
                // codigo: this.state.selectRowPerson.sis, // Ya no se guarda codigos sis desde cajas
                complemento_documento: this.state.selectRowPerson.complemento,
                apellido_1: this.state.selectRowPerson.paterno?.trim(),
                apellido_2: this.state.selectRowPerson.materno?.trim(),
                nombre_1: this.state.selectRowPerson.nombre_1?.trim(),
                nombre_2: this.state.selectRowPerson.nombre_2?.trim(),
                fecha_nacimiento: this.state.selectRowPerson.fecha_nacimiento,
                tipo_documento_id: 1, // WIP: Falta aniadir al formulario por el momento
                contactos: [{
                    valor: this.state.selectRowPerson.sis,
                    tipo: 'COD_EST',
                    estado: true,
                }]
            }
            if (this.state.registring) {
                await services.registerPerson(data)
                    .then((res) => {
                        if (res.isOK) {
                            personId = res.id
                        }
                    })
                    .catch(err => console.log(err))
            }
        }

        let movement = {
            fecha: new Date(),
            preimpreso: this.state.selectRowAccount.valorado ? "" : this.state.prePrint,
            precio: this.state.selectRowAccount.precio,
            cantidad: this.state.cantCustodia,
            observacion: this.state.observations,
            cuenta: this.state.selectRowAccount.id,
            cantidadRecibos: this.state.cantResiv,
            fecha_pago: new Date(),
            cuentas_asociadas: this.state.selectRowAccount.valorado ? [] : this.state.assocAccount,
            valorado: this.state.selectRowAccount.valorado,
            person: this.state.registring ? personId : this.state.selectRowPerson.id
        }

        if (!this.state.selectRowAccount.valorado) {
            this.saveCurrentAccount({
                ...this.state.selectRowPerson,
                id: personId ? personId : this.state.selectRowPerson.id,
            })
        }

        await services.registerMovement(movement)
            .then((res) => {
                if (res.isOK) {
                    if (!this.state.selectRowAccount.valorado) {
                        this.setState({
                            prePrint: parseInt(this.state.prePrint) + parseInt(this.state.cantResiv),
                            loading: false,
                        })
                        this.printReport(new Date(res.movement.fecha_pago), res.nroRecibos)
                    } else {
                        alert("Se registro la venta del valorado");
                        this.setState({ loading: false })
                        this.cleanForm()
                    }

                } else {
                    this.setState({ loading: false })
                    alert(res.result)
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log('ocurrio un error', err)
            })
    }
    cleanForm = () => {
        this.setState({
            selectRowAccount: { codigo: "", nombre: "", unidad: "", nro: 0, tipo_monto: "", precio: "" },
            assocAccount: [],
            selectRowPerson: { documento: "", sis: "", nombre_1: "", nombre_2: "", paterno: "", materno: "", complemento: "", fecha_nacimiento: "" },
            totalCustodia: '',
            cantCustodia: 1,
            accounts: [],
            sispay: "",
            cipay: "",
            observations: '',
            cantResiv: 1,
        })
        this.cleanErrors()
    }

    cleanErrors = () => {
        showError(true, "documento");
        showError(true, "complemento");
        showError(true, "sis");
        showError(true, "paterno");
        showError(true, "materno");
        showError(true, "nombre_1");
        showError(true, "nombre_2");
        showError(true, "codigo");
        showError(true, "nombre");
        showError(true, "unidad");
        showError(true, "nro");
    }

    //Calcula la suma total de de las cuentas asociadas
    sumTotal = () => {
        let suma = 0
        this.state.assocAccount.forEach((acc) => {
            if (acc.cantidad !== '') {
                suma += (acc.cantidad * acc.precio)
            }
        })
        return suma.toFixed(2)
    }

    updateAccount = (data) => {
        this.setState({ assocAccount: data })
    }

    closePopUp() {
        this.setState({ popUpMessageVisible: false })
        this.setState({ popUpMessage: '' })
    }

    render() {
        document.title = 'Caja UMSS - Recaudaciones'

        if (this.state.adminReport || this.state.rolReport || this.state.dticRole) {
            return <Navigate replace to="/reports" />
        }

        return (
            <>
                {this.state.spinner ? <LoadingSpiner /> : null}
                <div>
                    <div className='container shadow-sm bg-body rounded position-relative slight-responsive-increase' style={{ marginLeft: 'auto', marginRight: "auto", padding: 0 }}>
                        {this.state.closeMoviments ?
                            <div className='fade-pane'>
                                <div className='pane'>
                                    <div className='close-message'>
                                        <h3>
                                            La venta de valores se encuentra cerrada {this.state.closeType === 1 ? 'por el día de hoy' : 'para la presente gestión'}.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        <div style={{ padding: '1rem' }}>
                            <div className='header'>
                                <label className='title'>COBROS ELECTRÓNICOS - MATRÍCULAS - POSTULANTES</label>
                            </div>
                            <table className='table table-responsive table-borderless'>
                                <tbody>
                                    <tr>
                                        <th><label><strong>Código:</strong></label></th>
                                        <td><input type='number' className='form-control form-control-sm' id='sispay' name='inputPayLine' value={this.state.sispay} onKeyPress={this.handleAnswerChange} onChange={this.handleChangePay} disabled={this.state.closeMoviments} /></td>
                                        <th><label><strong>Documento:</strong></label></th>
                                        <td><input type='text' className='form-control form-control-sm' id='cipay' name='inputPayLine' value={this.state.cipay} onKeyPress={this.handleAnswerChange} onChange={this.handleChangePay} disabled={this.state.closeMoviments} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <section>
                                {this.state.accounts.length > 0 && this.state.textInputName === 'inputPayLine' ?
                                    <TableAccount
                                        handleShowModal={this.handleShowModal}
                                        handleCloseModal={this.handleCloseModal}
                                        searchDebtsDetails={this.searchDebtsDetails}
                                        accounts={this.state.accounts}
                                        textInputName={this.state.textInputName}
                                        title={this.state.titleModal}
                                        showModal={this.state.showModal}
                                        detailSisPay={this.state.detailSisPay}
                                        updatePreprint={this.updatePreprint}
                                        changePreprint={this.changePreprint}
                                        preprintNumber={this.state.prePrint}
                                        cleanInputPayline={this.cleanForm}
                                    />
                                    : this.state.showNotPay ?
                                        <div className='header mb-2' style={{ textAlign: 'center' }}>No existen resultados</div>
                                        : <></>
                                }
                            </section>

                            <div className='header clearfix'>
                                <label className='title float-start'>PERSONA</label>
                                <button type="button" className="btn btn-light-grey btn-sm float-end" onClick={this.getBackAccount} disabled={this.state.closeMoviments}>Recuperar Documento</button>
                            </div>
                            <table className='table table-responsive table-borderless'>
                                <tbody>
                                    <tr>
                                        <th>Documento: </th>
                                        <td>
                                            <input 
                                                ref={this.refDoc} 
                                                type='text' 
                                                id="documento" 
                                                name="inputPerson" 
                                                value={this.state.selectRowPerson.documento} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.handleAnswerChange} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.closeMoviments} 
                                                required 
                                            />
                                        </td>
                                        <th>Comp. </th>
                                        <td>
                                            <input 
                                                id="complemento" 
                                                value={this.state.selectRowPerson.complemento} 
                                                onChange={this.handleChangePerson} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                            />
                                        </td>
                                        <th>Código: </th>
                                        <td>
                                            <input 
                                                ref={(input) => { this.sisInput = input; }} 
                                                type='text' 
                                                id="sis" 
                                                name="inputPerson" 
                                                value={this.state.selectRowPerson.sis} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.handleAnswerChange} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.closeMoviments} 
                                                required 
                                            />
                                        </td>
                                        <th>Fecha Nac. </th>
                                        <td>
                                            <input 
                                                ref={this.refDate} 
                                                type='date' 
                                                id="fecha_nacimiento" 
                                                name="inputBirthday" 
                                                value={this.state.selectRowPerson.fecha_nacimiento} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.moveFocus}
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                                required 
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Apellido 1: </th>
                                        <td>
                                            <input 
                                                ref={this.refApellido1} 
                                                id="paterno" 
                                                value={this.state.selectRowPerson.paterno} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.moveFocus} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                            />
                                        </td>
                                        <th>Apellido 2: </th>
                                        <td>
                                            <input 
                                                ref={this.refApellido2} 
                                                id="materno" 
                                                value={this.state.selectRowPerson.materno} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.moveFocus} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                            />
                                        </td>
                                        <th>Nombre 1: </th>
                                        <td>
                                            <input 
                                                ref={this.refNombre1} 
                                                id="nombre_1" 
                                                value={this.state.selectRowPerson.nombre_1} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.moveFocus} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                                required 
                                            />
                                        </td>
                                        <th>Nombre 2: </th>
                                        <td>
                                            <input 
                                                ref={this.refNombre2} 
                                                id="nombre_2" 
                                                value={this.state.selectRowPerson.nombre_2} 
                                                onChange={this.handleChangePerson} 
                                                onKeyUp={this.moveFocus} 
                                                className='form-control form-control-sm' 
                                                disabled={this.state.disabledPerson || this.state.closeMoviments} 
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='header'>
                                <label className='title'>CUENTAS</label>
                            </div>
                            <table className='table table-responsive table-borderless mb-0'>
                                <tbody>
                                    <tr>
                                        <th className='size1' style={{ paddingLeft: '6px' }}>Código</th>
                                        <th className='size2' style={{ paddingLeft: '6px' }}>Nombre</th>
                                        <th className='size2' style={{ paddingLeft: '6px' }}>Unidad</th>
                                        <th className='size1' style={{ paddingLeft: '6px' }}>Nro.Preimpreso</th>
                                    </tr>
                                    <tr>
                                        <td className='ps-0'><input className='form-control form-control-sm' ref={this.refCod} id='codigo' name='inputAccount' type='text' onKeyPress={this.handleAnswerChange} value={this.state.selectRowAccount.codigo} onChange={this.handleChangeAccount} disabled={this.state.closeMoviments} /></td>
                                        <td className='ps-0'><input className='form-control form-control-sm' id='nombre' defaultValue={this.state.selectRowAccount.nombre} disabled /></td>
                                        <td className='ps-0'><input className='form-control form-control-sm' id='unidad' defaultValue={this.state.selectRowAccount.unidad} disabled /></td>
                                        <td className='ps-0'><input className='form-control form-control-sm' type='number' id='nro' value={this.state.prePrint} onChange={(e) => { this.changePreprint(e.target.value) }} style={{ textAlign: "right" }} disabled={this.state.closeMoviments} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='table table-responsive table-borderless'>
                                <tbody>
                                    <tr>
                                        <th>Cantidad de Recibos</th>
                                        <th className='pb-0' style={{ paddingLeft: '6px' }}>Precio Bs</th>
                                        <th className='pb-0' style={{ paddingLeft: '6px' }}>Cantidad</th>
                                        <th colSpan={2}></th>
                                    </tr>
                                    <tr >
                                        <td className='ps-0'><input id='cantReciv' style={{ textAlign: "right" }} type='number' name='cantReciv' className='form-control form-control-sm' value={this.state.cantResiv} onWheel={(e) => e.target.blur()} min={1} max={10} onChange={e => this.handleChange(e)} disabled={this.state.closeMoviments} /></td>
                                        <td className='ps-0'><input id='custodia' ref={this.refCust} type='text' name='inputCustodia' onKeyPress={this.setFocus} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} style={{ textAlign: "right" }} min={0} className='form-control form-control-sm' value={this.state.selectRowAccount.precio} onChange={this.updatePrice} disabled={this.state.disabled || this.state.closeMoviments} /></td>
                                        <td className='ps-0'><input id='cantCust' ref={this.refCantCust} style={{ textAlign: "right" }} onKeyPress={this.setFocus} type='number' name='cantCust' className='form-control form-control-sm' value={this.state.cantCustodia} onChange={this.totalCantidad} disabled={this.state.closeMoviments} /></td>
                                        <th>Total Importe Bs.</th>
                                        <td className='ps-0'><input type='number' className='form-control form-control-sm text-end' defaultValue={this.state.totalCustodia} disabled /></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.assocAccount.length > 0 ?
                                    <Account account={this.state.assocAccount}
                                        handleModal={this.handleModal}
                                        sumTotal={this.sumTotal}
                                        updateAccount={this.updateAccount}
                                    /> : //si no hay cuentas asociadas para visualizar se muestra un mensaje
                                    <>
                                        <div className='header' style={{ textAlign: 'center', marginBlock: '1%' }}>No hay cuentas asociadas</div>
                                        {   //se visualiza boton para aniadir una cuenta asociada
                                            this.state.selectRowAccount.nombre !== '' ? <button type="button" style={{ marginBottom: "1%" }} className="btn btn-primary btn-sm" onClick={(e) => this.handleModal()}>Adicionar Cuentas</button> : null
                                        }
                                    </>
                            }
                            <table>
                                <tbody>
                                    <tr style={{ width: "100%" }}>
                                        <td style={{ width: "10%" }}><b>Aclaración: </b></td>
                                        <td style={{ width: "60%" }}><input className='form-control form-control-sm' ref={this.refObs} name="inputObs" onKeyPress={this.setFocus} maxLength={255} value={this.state.observations} onChange={this.handleObservations} disabled={this.state.closeMoviments} /></td>
                                        <td className='ps-2' style={{ width: "15%" }}><b>Total General Bs.</b></td>
                                        <td style={{ width: "15%" }}><input className='form-control form-control-sm' style={{ textAlign: "right" }} value={isNaN((parseFloat(this.sumTotal()) + parseFloat(this.state.totalCustodia)).toFixed(2)) ? 0 : (parseFloat(this.sumTotal()) + parseFloat(this.state.totalCustodia)).toFixed(2)} disabled /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='header' style={{ marginBlock: '1%' }}>
                                <table className='table table-responsive table-borderless mb-0'>
                                    <tbody>
                                        <tr>
                                            <td className='buttons'>
                                                <button type="button" className="btn btn-primary bt2"
                                                    id="btnTerminar"
                                                    ref={this.refBtnFinish}
                                                    disabled={this.state.loading || this.state.closeMoviments}
                                                    onClick={() => this.submit()}
                                                    onKeyPress={this.handleKeyPress}>
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )} <span>Terminar</span>
                                                </button>
                                                <button type="button" className="btn btn-light-grey bt2" onClick={() => this.cleanForm()} disabled={this.state.closeMoviments}>Limpiar</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    handleCloseModal={this.handleCloseModal}
                    handleShowModal={this.handleShowModal}
                    showInputAccount={this.showInputAccount}
                    showInputPerson={this.showInputPerson}
                    setBusquedaData={this.setBusquedaData}
                    getListAssocAccounts={this.getListAssocAccounts}
                    showModal={this.state.showModal}
                    busqueda={this.state.busqueda}
                    accounts={this.state.accounts}
                    textInputName={this.state.textInputName}
                    title={this.state.titleModal}
                    refSearch={this.refSearch}
                    typeSearch={this.state.typeSearch}
                    detailSisPay={this.state.detailSisPay}
                    searchAccount={this.searchAccount}
                    searchPerson={this.searchPerson}
                    searchAddAccount={this.searchAddAccount}
                    addAssocAccounts={this.addAssocAccounts}
                />
                <PopUpMessage
                    close={this.closePopUp}
                    visible={this.state.popUpMessageVisible}
                    message={this.state.popUpMessage}
                />
            </>
        );
    }
}

export default Sales;
