import authHeader from './auth-header'
import { BaseRequest } from './request/baseRequest'
import { GenericRequest } from './request/request'

const PresupuestosServices = {
    claseGasto: {
        list: (params = {}) => GenericRequest.get('presupuestos/list-clase-gasto', params),
        create: (data) => GenericRequest.post('presupuestos/create-clase-gasto', data),
        update: (id, data) => GenericRequest.post('presupuestos/update-clase-gasto', data, { id }),
        delete: (id) => GenericRequest.post('presupuestos/delete-clase-gasto', {}, { id }),
    },
    objetoGasto: {
        list: (params = {}) => GenericRequest.get('presupuestos/list-objeto-gasto', params),
        update: (id, data) => GenericRequest.post('presupuestos/update-objeto-gasto', data, { id }),
        search: (partida) => GenericRequest.get('presupuestos/search-objetos-gasto', { partida }),
    },
    detalleGasto: {
        list: (params = {}) => GenericRequest.get('presupuestos/list-detalle-gasto', params),
        create: (data) => GenericRequest.post('presupuestos/create-detalle-gasto', data),
        update: (id, data) => GenericRequest.post('presupuestos/update-detalle-gasto', data, { id }),
        delete: (id) => GenericRequest.post('presupuestos/delete-detalle-gasto', {}, { id }),
        search: (params) => GenericRequest.get('presupuestos/search-detalle-gasto', params),
    },
    fondo: {
        previsionPdf: (data = {}) =>
            BaseRequest.post('presupuestos/prevision-pdf', data, { responseType: 'blob', headers: authHeader() }),
        previsionXlsx: (data = {}) =>
            BaseRequest.post('presupuestos/prevision-xlsx', data, { responseType: 'blob', headers: authHeader() }),
    },
}

export default PresupuestosServices
