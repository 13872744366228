import { Modal } from 'modules/modular/Modal'
import FormDetalleFondo from './FormDetalleFondo'

export default function ModalFormDetalleFondo({ show, onHide, onExited, fondo, selected, onSubmit }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            onExited={onExited}
            closeOnBackdrop={false}
            keyboard={false}
            style={{ width: '30rem' }}
        >
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>{selected ? 'Editar' : 'Añadir'} detalle</div>
            <FormDetalleFondo fondo={fondo} selected={selected} onCancel={onHide} onSubmit={onSubmit} />
        </Modal>
    )
}
