import { initialPagination } from 'data/constants'
import useFilters, { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { AdvancedSelect } from 'modules/modular/AdvancedSelect'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Modal } from 'modules/modular/Modal'
import { useEffect, useMemo, useState } from 'react'
import PresupuestosServices from 'services/presupuestos.service'
import { pick } from 'utils'

const initialValues = {
    partida: '',
    descripcion: '',
    clase_gasto_id: '',
}
const filterOptions = { contain: ['partida', 'descripcion', 'clase_gasto'] }

export default function ObjetosGasto() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize, handleSort, handleFilter, reloadFilters } = useFilters({
        filterOptions,
    })
    const [objetos, setObjetos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [isVisible, setIsVisible] = useState({ modalForm: false, modalConfirmDelete: false })
    const [values, setValues] = useState(initialValues)
    const [submitting, setSubmitting] = useState(false)
    const [clasesGasto, setClasesGasto] = useState([])

    useEffect(() => {
        const loadClasesGasto = async () => {
            const { status, data } = await PresupuestosServices.claseGasto.list({
                all: true,
                sort: 'codigo',
                'filter[activo]': 1,
            })
            if (status === 200) {
                setClasesGasto(data.data)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadClasesGasto()
    }, [])
    useEffect(() => {
        const loadList = async () => {
            const params = objectToQueryString(filters)
            const { status, data } = await PresupuestosServices.objetoGasto.list(params)
            if (status === 200) {
                setObjetos(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'update') {
            setValues(rowData)
            show('modalForm')
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='text-center'>
            <ButtonIcon
                onClick={() => handleAction('update', data)}
                style={{ fontSize: '1.2rem' }}
                disabled={!data.activo}
            >
                <span className='bi-pencil lh-1' style={{ fontSize: '1rem' }} />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            {
                field: 'partida',
                header: 'Partida',
                sortable: true,
                className: 'text-center',
                filter: true,
                style: { width: '10rem' },
            },
            { field: 'descripcion', header: 'Descripción', sortable: true, filter: true },
            {
                field: 'clase_gasto',
                header: 'Clase Gasto',
                headerClassName: 'text-center',
                cell: ({ data }) =>
                    data.clase_gasto ? `${data.clase_gasto_codigo} - ${data.clase_gasto}` : 'NO ASIGNADO',
                filter: true,
            },
            {
                id: 'options',
                header: 'Opciones',
                headerClassName: 'text-center',
                headerStyle: { width: '10rem' },
                cell: optionsTemplate,
            },
        ],
        [],
    )

    const handleChangeValues = (e) => {
        const name = e.target.name
        setValues((prev) => ({ ...prev, [name]: e.target.value }))
    }
    const handleUpdate = async () => {
        setSubmitting(true)
        if (values.id) {
            const { status, data } = await PresupuestosServices.objetoGasto.update(
                values.id,
                pick(values, 'clase_gasto_id'),
            )
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                hide('modalForm')
                reloadFilters()
            }
        }
        setSubmitting(false)
    }
    const clearStates = () => {
        setValues(initialValues)
    }

    return (
        <div className='h-100 p-4'>
            <div className='container p-0 mx-auto my-0 h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Objetos de Gasto</h2>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={objetos}
                        columns={columns}
                        lazy
                        pagination
                        totalItems={pagination.totalRecords}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        pageSizeOptions={[10, 25, 50]}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        onSort={handleSort}
                        onFilter={handleFilter}
                        filterable
                    />
                    <Modal show={isVisible.modalForm} onExited={clearStates}>
                        <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Editar Objeto de Gasto</div>
                        <div className='p-3'>
                            <FieldGroup label='Partida'>
                                <Input disabled defaultValue={values.partida} />
                            </FieldGroup>
                            <FieldGroup label='Descripción'>
                                <Input disabled defaultValue={values.descripcion} />
                            </FieldGroup>
                            <FieldGroup label='Clase de gasto'>
                                <AdvancedSelect
                                    options={clasesGasto}
                                    optionLabel='nombre'
                                    optionValue='id'
                                    name='clase_gasto_id'
                                    value={values.clase_gasto_id}
                                    onChange={handleChangeValues}
                                    containerClassName='w-100'
                                    menuStyle={{ minWidth: '100%' }}
                                    optionTemplate={(option) => `${option.codigo} - ${option.nombre}`}
                                    clearable
                                />
                            </FieldGroup>
                        </div>
                        <div className='px-3 py-2 border-top text-end'>
                            <Button
                                className='me-2'
                                variant='outline-primary'
                                onClick={() => hide('modalForm')}
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleUpdate}
                                startIcon={<span className='bi-floppy' />}
                                loading={submitting}
                            >
                                Guardar
                            </Button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}
