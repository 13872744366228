import { Dropdown, useDropdownMenu, useDropdownToggle } from '@restart/ui'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { classNames, joinStyles, propFromName, empty } from '../utils'
import { BaseDropdownMenuCheckbox } from '../base/BaseDropdownMenu'
import { XLg } from '../icons/XLg'
import { createPortal } from 'react-dom'
export function AdvancedSelect({
    value,
    valueAsOption,
    onChange,
    onFocus,
    onBlur,
    placeholder = 'Seleccionar',
    name = '',
    id = '',
    options,
    optionValue = 'value',
    optionLabel = 'label',
    optionTemplate,
    selectedTemplate,
    className,
    style,
    containerClassName,
    containerStyle,
    menuClassName,
    menuStyle,
    multiSelection = false,
    clearable,
    filterBy,
    filterPlaceholder = 'Buscar',
    lazy,
    onSearch,
    portal,
}) {
    const history = useRef([])
    const [show, setShow] = useState(false)
    const [hovered, setHovered] = useState(-1)
    const [filterValue, setFilterValue] = useState('')
    const { menuOptions, values, selected } = useMemo(() => {
        const _selected = {}
        // Verificar los seleccionados en el historial
        if (!empty(value) && history.current.length) {
            history.current.forEach((option, index) => {
                // Obtener la key de la opción
                const key = getOptionProp(option, optionValue) ?? `option-${index}`
                // Verificar si la opción esta seleccionada
                const _isSelected = isSelected(!!valueAsOption, option, value, optionValue)
                if (_isSelected) _selected[key] = option
            })
        }
        const _options = options.reduce((carry, option, index) => {
            // Obtener la key de la opción
            const key = getOptionProp(option, optionValue) ?? `option-${index}`
            // Verificar si la opción esta seleccionada
            const _isSelected = isSelected(!!valueAsOption, option, value, optionValue)
            if (_isSelected) _selected[key] = option
            // Filtrar las opciones
            const insert = filterBy && !lazy ? startsWith(getOptionProp(option, filterBy), filterValue) : true
            if (insert) {
                carry.push({
                    key,
                    index,
                    selected: _isSelected,
                    data: option,
                })
            }
            return carry
        }, [])
        return { menuOptions: _options, values: Object.values(_selected), selected: _selected }
    }, [options, value, optionValue, valueAsOption, lazy, filterBy, filterValue])
    useEffect(() => {
        if (!show && hovered >= 0) setHovered(-1)
    }, [show])
    const buildEvent = (option) => {
        return {
            target: {
                name,
                id,
                value: valueAsOption
                    ? option
                    : multiSelection
                      ? option.map((o) => getOptionProp(o, optionValue))
                      : getOptionProp(option, optionValue),
            },
        }
    }
    const handleSelect = (option) => {
        if (filterBy && lazy) history.current.push(option.data)
        if (multiSelection) {
            let _values = []
            if (option.selected) {
                for (const key in selected) {
                    option.key !== key && _values.push(selected[key])
                }
            } else {
                _values = [...values, option.data]
            }
            onChange?.(buildEvent(_values))
        } else {
            if (!option.selected) {
                onChange?.(buildEvent(option.data))
            }
            setShow(false)
        }
    }
    const handleFocus = () => {
        onFocus?.(buildEvent(value))
    }
    const handleBlur = () => {
        onBlur?.(buildEvent(value))
    }
    const handleClear = (e) => {
        e.stopPropagation()
        onChange?.(buildEvent(multiSelection ? [] : ''))
    }
    const handleKeyDown = (e) => {
        if (show) {
            switch (e.key) {
                case 'Enter':
                    if (hovered >= 0) {
                        handleSelect(menuOptions[hovered])
                    }
                    break
                case 'ArrowUp':
                    const lastIndex = hovered - 1
                    if (lastIndex < 0) {
                        setShow(false)
                    }
                    setHovered(lastIndex)
                    break
                case 'ArrowDown':
                    const nextIndex = hovered + 1
                    if (nextIndex < menuOptions.length) {
                        setHovered(nextIndex)
                    }
                    break
                default:
                    break
            }
        }
    }
    const handleChangeFilter = (e) => {
        setFilterValue(e.target.value)
        lazy && onSearch?.(e.target.value)
    }
    const menuHeader = filterBy && (
        <input
            value={filterValue}
            onChange={handleChangeFilter}
            onKeyDown={handleKeyDown}
            placeholder={filterPlaceholder}
            className='mod-as-filter'
        />
    )
    return (
        <div className={classNames('modular-advanced-select', containerClassName)} style={containerStyle}>
            <Dropdown show={show} onToggle={(nextShow) => setShow(nextShow)}>
                <AdvancedSelectField
                    values={values}
                    optionLabel={optionLabel}
                    selectedTemplate={selectedTemplate}
                    placeholder={placeholder}
                    multiSelection={multiSelection}
                    onClear={clearable ? handleClear : undefined}
                    onFocus={onFocus ? handleFocus : undefined}
                    onBlur={onBlur ? handleBlur : undefined}
                    onKeyDown={handleKeyDown}
                    className={className}
                    style={style}
                />
                {show && (
                    <AdvancedSelectMenu
                        options={menuOptions}
                        optionLabel={optionLabel}
                        optionTemplate={optionTemplate}
                        onSelect={handleSelect}
                        headerElement={menuHeader}
                        className={menuClassName}
                        style={menuStyle}
                        multiSelection={multiSelection}
                        hovered={hovered}
                        portal={portal}
                    />
                )}
            </Dropdown>
        </div>
    )
}
function AdvancedSelectField({
    values,
    optionLabel,
    selectedTemplate,
    placeholder,
    multiSelection,
    onClear,
    ...props
}) {
    const [toggleProps, { show, toggle }] = useDropdownToggle()
    const handleClick = useCallback(
        (e) => {
            toggleProps.onClick(e)
        },
        [toggleProps.onClick],
    )
    return (
        <div
            {...props}
            ref={(el) => toggleProps.ref(el)}
            onClick={handleClick}
            className={classNames('mod-as-field', props.className)}
            tabIndex={0}
            aria-expanded={toggleProps['aria-expanded']}
        >
            <div className='mod-asf-label'>
                {selectedTemplate?.(multiSelection ? values : (values[0] ?? '')) ??
                    (values.map((value) => getOptionProp(value, optionLabel)).join(', ') || (placeholder ?? ''))}
            </div>
            {values.length > 0 && onClear && (
                <div className='mod-asf-clear' onClick={onClear}>
                    <XLg />
                </div>
            )}
        </div>
    )
}
function AdvancedSelectMenu({
    options,
    optionLabel,
    optionTemplate,
    onSelect,
    headerElement,
    multiSelection,
    hovered,
    portal,
    ...props
}) {
    const optionsRef = useRef([])
    const [menuProps, { show, popper }] = useDropdownMenu({
        flip: true,
        offset: [0, 4],
        placement: 'bottom-start',
    })
    useLayoutEffect(() => {
        if (show) popper?.update()
    }, [show])
    useEffect(() => {
        if (hovered >= 0 && hovered < options.length) {
            optionsRef.current[hovered].scrollIntoView({ block: 'nearest' })
        }
    }, [hovered])
    const menuElement = (
        <div
            {...menuProps}
            {...props}
            className={classNames('mod-as-menu', props.className)}
            style={joinStyles(menuProps.style, props.style)}
            tabIndex={-1}
        >
            {headerElement && <div className='mod-asm-header'>{headerElement}</div>}
            <div className='mod-asm-options'>
                {options.length > 0 ? (
                    options.map((option, index) => (
                        <div
                            key={option.key}
                            ref={(el) => {
                                if (el) optionsRef.current[index] = el
                            }}
                            className={classNames('mod-asm-option', {
                                selected: option.selected,
                                hovered: hovered === index,
                            })}
                            onClick={onSelect ? () => onSelect(option) : undefined}
                        >
                            {multiSelection && (
                                <div className='mod-asmo-checkbox'>
                                    <BaseDropdownMenuCheckbox
                                        checked={option.selected}
                                        onChange={() => {}}
                                        tabIndex={-1}
                                    />
                                </div>
                            )}
                            <div className='mod-asmo-label'>
                                {optionTemplate?.(option.data) ?? getOptionProp(option.data, optionLabel)}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='mod-asm-empty-option'>Sin opciones</div>
                )}
            </div>
        </div>
    )
    return portal ? createPortal(menuElement, document.body) : menuElement
}
const getOptionProp = (option, optionProp) => {
    if (typeof option === 'object' && option !== null) {
        const _prop = propFromName(option, optionProp)
        return toString(_prop)
    } else {
        return String(option ?? '')
    }
}
const toString = (data) => {
    if (data === null || data === undefined) {
        return ''
    }
    if (typeof data === 'object') {
        let str = data.toString()
        if (str === '[object Object]') {
            str = JSON.stringify(data)
        }
        return str
    }
    return String(data)
}
const isSelected = (valueAsOption, option, value, optionValue) => {
    const selected = Array.isArray(value) ? value : value ? [value] : []
    if (valueAsOption) {
        return selected.some((s) => toString(s) === toString(option))
    } else {
        return selected.some((s) => toString(s) === getOptionProp(option, optionValue))
    }
}
function startsWith(str, prefix) {
    return toLowerCase(str).startsWith(toLowerCase(prefix))
}
function toLowerCase(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
}
