import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'

const claveManual = 'manual_fondo_rotatorio_y_fondo_avance'
const claveVideo = 'video_fondo_rotatorio_y_fondo_avance'
const claveTaller = 'video_taller_fondo_rotatorio_y_fondo_avance_v1'

export function Ayuda() {
    const [urlManual, setUrlManual] = useState('')
    const [urlVideo, setUrlVideo] = useState('')
    const [urlTaller, setUrlTaller] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ContabilidadServices.getConfiguracion({
                claves: [claveManual, claveVideo, claveTaller],
            })
            if (status === 200) {
                if (data.data[claveManual]) {
                    const _urlManual = await getPdfObjectUrl(data.data[claveManual].valor)
                    setUrlManual(_urlManual)
                }
                if (data.data[claveVideo]) {
                    setUrlVideo(data.data[claveVideo].valor)
                }
                if (data.data[claveTaller]) {
                    setUrlTaller(data.data[claveTaller].valor)
                }
            }
            setLoading(false)
        }
        load()
    }, [])

    const getPdfObjectUrl = async (filename) => {
        // Paso 1: Descargar el PDF desde la URL
        const { status, data } = await ContabilidadServices.getManual(filename)
        if (status === 200) {
            // Paso 2: Crear un ObjectURL a partir del Blob
            const blobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
            return blobUrl
        }
        return ''
    }

    const skeletonVideo = (
        <div
            className='w-100 mb-3 d-flex align-items-center justify-content-center'
            style={{
                aspectRatio: '16 / 9',
                backgroundColor: '#ccc',
                animation: 'pulse 1.5s infinite ease-in-out',
            }}
        >
            <div className='spinner-border text-light' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    )

    return (
        <div className='d-flex flex-column gap-4 align-items-center p-4'>
            <div className='container mt-0'>
                <div className='bg-blue text-white py-3 px-4 mb-3'>
                    <h2 className='mb-0 fw-normal'>Guía de Fondo Rotatorio y Fondo en Avance</h2>
                </div>
                {loading ? (
                    <>
                        {skeletonVideo}
                        {skeletonVideo}
                    </>
                ) : (
                    <>
                        {urlTaller && (
                            <div className='w-100 mb-3' style={{ aspectRatio: '16 / 9' }}>
                                <iframe
                                    style={{ width: '100%', height: '100%' }}
                                    src={urlTaller}
                                    title='YouTube video player'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    referrerPolicy='strict-origin-when-cross-origin'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                        {urlVideo && (
                            <div className='w-100 mb-3' style={{ aspectRatio: '16 / 9' }}>
                                <iframe
                                    style={{ width: '100%', height: '100%' }}
                                    src={urlVideo}
                                    title='YouTube video player'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    referrerPolicy='strict-origin-when-cross-origin'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                    </>
                )}

                <div className='w-100' style={{ height: '100vh', backgroundColor: '#e9ecef' }}>
                    {loading ? (
                        <div
                            className='w-100 h-100 d-flex align-items-center justify-content-center'
                            style={{
                                backgroundColor: '#ccc',
                                animation: 'pulse 1.5s infinite ease-in-out',
                            }}
                        >
                            <div className='spinner-border text-light' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        </div>
                    ) : urlManual ? (
                        <embed src={urlManual} type='application/pdf' className='w-100 h-100'></embed>
                    ) : (
                        <div
                            className='h-100 d-flex align-items-center justify-content-center fs-5'
                            style={{ border: '2px solid #dee2e6' }}
                        >
                            Manual no disponible
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
