import { GenericRequest } from './request/request'

const RecaudacionesServices = {
    persona: {
        list: (params) => GenericRequest.get('recaudaciones/list-persona', params),
        update: (id, data) => GenericRequest.post('recaudaciones/update-persona', data, { id }),
    },
}

export default RecaudacionesServices
