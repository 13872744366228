import '../Styles/navbar.css'
import { React, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from 'utils'
import { titlePages } from 'data/constants'
import { logout } from 'actions/auth'
import Header from './Header'
import useRoutesByRole from 'hooks/useRoutesByRole'
import { verifyAccess } from 'utils/verifyAccess'

// Datos de los enlaces, par Ruta -> Datos del enlace
const pageLinks = {
    sales: { path: '/sales', label: 'Venta de Valores', icon: 'fa fa-shopping-cart' },
    reports: { path: '/reports', label: 'Reporte de Movimientos', icon: 'fa fa-file-pdf-o' },
    receiptSearch: { path: '/receiptSearch', label: 'Anular/Re-imprimir Recibos', icon: 'fa fa-file-text' },

    facturas: { path: '/facturas', label: 'Facturar', icon: 'fa fa-shopping-cart' },
    anularFacturas: { path: '/anularFacturas', label: 'Anular/Re-imprimir Facturas', icon: 'fa fa-file-text' },
    reportsFacturas: { path: '/reportsFacturas', label: 'Reporte de Facturas', icon: 'fa fa-file-pdf-o' },

    kardexValores: { path: '/kardexValores', label: 'Ingreso de Valores', icon: 'fa fa-pencil-square-o' },
    chartaccount: { path: '/chartaccount', label: 'Tarifario de Cuentas', icon: 'fa fa-list' },
    locking: { path: '/locking', label: 'Cierre de Movimientos', icon: 'fa fa-clock-o' },
    'last-movement': { path: '/last-movement', label: 'Borrar Movimiento de Valorados', icon: 'fa fa-trash-o' },
}
const contabilidadLinks = [
    {
        path: 'contabilidad/usuario',
        label: 'Usuarios',
        icon: 'bi-person',
        required: ['AdministradorContabilidad', 'AdministradorContabilidadDA'],
        operator: 'OR',
    },
    {
        path: 'contabilidad/fondo',
        label: 'Fondos',
        icon: 'bi-cash',
        required: ['AdministradorContabilidad', 'AdministradorContabilidadDA'],
        operator: 'OR',
    },
    {
        path: 'contabilidad/fondo-rotatorio',
        label: 'Fondo-Rotatorio',
        icon: 'bi-cash-stack',
        required: ['BeneficiarioFondoRotatorio'],
    },
    {
        path: 'contabilidad/fondo-avance',
        label: 'Fondo-Avance',
        icon: 'bi-cash',
        required: ['BeneficiarioFondoAvance'],
    },
    {
        path: 'contabilidad/fondo/ayuda',
        label: 'Guía',
        icon: 'bi-question-circle',
        required: ['BeneficiarioFondoRotatorio', 'BeneficiarioFondoAvance'],
        operator: 'OR',
    },
]
const presupuestosLinks = [
    {
        path: 'presupuestos/clase-gasto',
        label: 'Clase-Gasto',
        icon: 'bi-card-checklist',
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/objeto-gasto',
        label: 'Objeto-Gasto',
        icon: 'bi-card-list',
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/detalle-gasto',
        label: 'Detalle-Gasto',
        icon: 'bi-cash-coin',
        required: ['AdministradorPresupuestos'],
    },
    {
        path: 'presupuestos/fondo',
        label: 'Fondos',
        icon: 'bi-cash',
        required: ['AdministradorPresupuestos', 'ReportesPresupuestosDA'],
        operator: 'OR',
    },
]
const recaudacionesLinks = [
    {
        path: 'recaudaciones/persona/update',
        label: 'Modificar-Persona',
        icon: 'bi-person-fill',
        required: ['admin'],
    },
]

function Navbar() {
    const dispatch = useDispatch()
    const routes = useRoutesByRole()
    const { pathname } = useLocation()
    const { user } = useSelector((state) => state.auth)
    const [title, setTitle] = useState('')
    const [header, setHeader] = useState('')

    useEffect(() => {
        const path = pathname.slice(1)
        setTitle(titlePages[path] || '')
        const _header =
            pathname.includes('fondo-rotatorio') || pathname.includes('fondo-avance')
                ? 'FONDOS ROTATORIO Y/O FONDOS EN AVANCE'
                : pathname.includes('contabilidad')
                ? 'CONTABILIDAD'
                : pathname.includes('presupuestos')
                ? 'PRESUPUESTOS'
                : 'SISTEMA RECAUDACIONES'
        header !== _header && setHeader(_header)
    }, [pathname])

    function handleLogout() {
        setTitle('')
        dispatch(logout())
    }

    return (
        <>
            <div className='row g-0 align-items-center bg-blue text-white py-2 px-4'>
                <div className='col-md-auto d-none d-lg-block px-1' style={{ width: '25rem' }}>
                    <img src={require('../data/img/logo2.png')} alt='logo umss' width={180} />
                </div>
                <div className='navbar-title col'>{header}</div>
                <div className='col-md-auto' style={{ width: '25rem', lineHeight: '1.35rem' }}>
                    {user && (
                        <>
                            <label className='d-block text-truncate'>Usuario: {user.name}</label>
                            <label className='d-block text-truncate'>
                                Unidad: {user.unidad ?? user.actividad ?? 'NO ASIGNADO'}
                            </label>
                            <label className='d-block'>Fecha: {formatDate(new Date(), 'd/m/Y')}</label>
                        </>
                    )}
                </div>
            </div>
            <div className='navbar-links'>
                {user && (
                    <>
                        {routes.map((route, index) =>
                            pageLinks[route] ? (
                                <NavLink key={index} to={pageLinks[route].path} className='btn link'>
                                    <i className={pageLinks[route].icon}></i> {pageLinks[route].label}
                                </NavLink>
                            ) : null,
                        )}
                        {contabilidadLinks.map(
                            (link, index) =>
                                verifyAccess(link.required, link.operator) && (
                                    <NavLink key={`c-${index}`} to={link.path} className='btn link'>
                                        <i className={link.icon}></i> {link.label}
                                    </NavLink>
                                ),
                        )}
                        {presupuestosLinks.map(
                            (link, index) =>
                                verifyAccess(link.required, link.operator) && (
                                    <NavLink key={`p-${index}`} to={link.path} className='btn link'>
                                        <i className={link.icon}></i> {link.label}
                                    </NavLink>
                                ),
                        )}
                        {recaudacionesLinks.map(
                            (link, index) =>
                                verifyAccess(link.required, link.operator) && (
                                    <NavLink key={`r-${index}`} to={link.path} className='btn link'>
                                        <i className={link.icon}></i> {link.label}
                                    </NavLink>
                                ),
                        )}
                        <button className='btn link' onClick={handleLogout}>
                            <i className='fa fa-sign-out'></i> Salir
                        </button>
                    </>
                )}
            </div>
            {user && title && <Header title={title} currentUser={user} greyBackground={true} />}
        </>
    )
}

export default Navbar
