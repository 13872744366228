import { initialPagination } from 'data/constants'
import useFilters from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Confirm, Modal } from 'modules/modular/Modal'
import { classNames } from 'modules/modular/utils'
import { useEffect, useMemo, useState } from 'react'
import PresupuestosServices from 'services/presupuestos.service'

const initialValues = {
    nombre: '',
    codigo: '',
    activo: true,
}

export default function ClasesGasto() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize, handleSort, reloadFilters } = useFilters()
    const [clases, setClases] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [isVisible, setIsVisible] = useState({ modalForm: false, modalConfirmDelete: false })
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        const loadList = async () => {
            const { status, data } = await PresupuestosServices.claseGasto.list(filters)
            if (status === 200) {
                setClases(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters])

    const show = (name) => setIsVisible((prev) => ({ ...prev, [name]: true }))
    const hide = (name) => setIsVisible((prev) => ({ ...prev, [name]: false }))

    const handleAction = (action, rowData = null) => {
        if (action === 'toggleActive') {
            handleToggleActive(rowData)
        } else {
            rowData && setValues(rowData)
            if (action === 'update') {
                show('modalForm')
            } else if (action === 'delete') {
                show('modalConfirmDelete')
            }
        }
    }

    const optionsTemplate = ({ data }) => (
        <div className='text-center'>
            <ButtonIcon
                onClick={() => handleAction('update', data)}
                style={{ fontSize: '1.2rem' }}
                disabled={!data.activo}
            >
                <span className='bi-pencil lh-1' style={{ fontSize: '1rem' }} />
            </ButtonIcon>
            <ButtonIcon
                variant={data.activo ? 'danger' : 'success'}
                className='ms-2'
                onClick={() => handleAction('toggleActive', data)}
                title={data.activo ? 'Desactivar' : 'Activar'}
                style={{ fontSize: '1.2rem' }}
            >
                <span
                    className={classNames('lh-1', data.activo ? 'bi-lock' : 'bi-unlock')}
                    style={{ fontSize: '1rem' }}
                />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            { field: 'nombre', header: 'Nombre', sortable: true },
            { field: 'codigo', header: 'Código', sortable: true, className: 'text-center' },
            {
                field: 'activo',
                header: 'Activo',
                cell: ({ value }) => (value ? 'Si' : 'No'),
                className: 'text-center',
            },
            {
                id: 'options',
                header: 'Opciones',
                headerClassName: 'text-center',
                headerStyle: { width: '10rem' },
                cell: optionsTemplate,
            },
        ],
        [],
    )

    const validateValues = (values) => {
        const _errors = {}
        if (!values.codigo) _errors.codigo = 'Requerido'
        if (!values.nombre) _errors.nombre = 'Requerido'
        return {
            hasErrors: Object.values(_errors).length > 0,
            errors: _errors,
        }
    }
    const handleChangeValues = (e) => {
        const name = e.target.name
        setValues({ ...values, [name]: e.target.value })
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        const { hasErrors, errors } = validateValues(values)
        if (hasErrors) {
            setErrors(errors)
        } else {
            if (values.id) {
                await handleUpdate()
            } else {
                await handleCreate()
            }
        }
        setSubmitting(false)
    }
    const handleCreate = async () => {
        const { status, data } = await PresupuestosServices.claseGasto.create(values)
        showHttpToast(status, { detail: data.message })
        if (status === 201) {
            hide('modalForm')
            reloadFilters()
        }
    }
    const handleUpdate = async () => {
        if (values.id) {
            const { status, data } = await PresupuestosServices.claseGasto.update(values.id, values)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                hide('modalForm')
                reloadFilters()
            }
        }
    }
    const handleDelete = async () => {
        if (values.id) {
            const { status, data } = await PresupuestosServices.claseGasto.delete(values.id)
            showHttpToast(status, { detail: data.message })
            hide('modalConfirmDelete')
            if (status === 200) {
                reloadFilters()
            }
        }
    }
    const handleToggleActive = async (value) => {
        if (value.id) {
            const bodyParams = { activo: !value.activo }
            const { status, data } = await PresupuestosServices.claseGasto.update(value.id, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
        }
    }
    const clearStates = () => {
        setValues(initialValues)
        setErrors({})
    }

    return (
        <div className='h-100 p-4'>
            <div className='container p-0 mx-auto my-0 h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Clases de Gasto</h2>
                    <div className='ms-auto'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            variant='light'
                            onClick={() => show('modalForm')}
                        >
                            Nueva Clase
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={clases}
                        columns={columns}
                        lazy
                        pagination
                        totalItems={pagination.totalRecords}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        onSort={handleSort}
                    />
                    <Confirm
                        show={isVisible.modalConfirmDelete}
                        onHide={() => hide('modalConfirmDelete')}
                        onReject={() => hide('modalConfirmDelete')}
                        onAccept={handleDelete}
                        onExited={clearStates}
                    >
                        <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                            ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> la clase de gasto "
                            {values.nombre}"?
                        </span>
                    </Confirm>
                    <Modal show={isVisible.modalForm} onExited={clearStates}>
                        <div className='px-3 py-2 border-bottom fs-4 fw-medium'>
                            {values.id ? 'Editar' : 'Registrar'} Clase de Gasto
                        </div>
                        <div className='p-3'>
                            <FieldGroup label='Código' feedback={errors.codigo} showFeedback={!!errors.codigo}>
                                <Input
                                    type='number'
                                    name='codigo'
                                    value={values.codigo}
                                    onChange={handleChangeValues}
                                    isInvalid={!!errors.codigo}
                                />
                            </FieldGroup>
                            <FieldGroup label='Nombre' feedback={errors.nombre} showFeedback={!!errors.nombre}>
                                <Input
                                    name='nombre'
                                    value={values.nombre}
                                    onChange={handleChangeValues}
                                    isInvalid={!!errors.nombre}
                                />
                            </FieldGroup>
                            <label className='d-inline-flex align-items-center gap-2'>
                                Activo:
                                <Checkbox
                                    name='activo'
                                    value={values.activo}
                                    onChange={handleChangeValues}
                                    style={{ fontSize: '1.375rem' }}
                                />
                            </label>
                        </div>
                        <div className='px-3 py-2 border-top text-end'>
                            <Button
                                className='me-2'
                                variant='outline-primary'
                                onClick={() => hide('modalForm')}
                                startIcon={<span className='bi-x-lg' />}
                                disabled={submitting}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                startIcon={<span className='bi-floppy' />}
                                loading={submitting}
                            >
                                Guardar
                            </Button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}
