/**
 * Formatea un valor a formato de moneda con 2 decimales.
 * @param {number | string} value Número.
 * @param {object} config Configuración del formateo.
 * @param {string} config.defaultValue Valor predeterminado devuelto si el número no se puede formatear.
 * @param {'.' | ','} config.decimalSeparator Separador de decimales.
 * @param {'' | '.' | ','} config.thousandsSeparator Separador de miles.
 */
export function formatCurrency(value, config = {}) {
    const { decimalSeparator, thousandsSeparator } = config ?? {}
    const defaultValue = config?.defaultValue ?? ''
    value = typeof value === 'string' ? parseFloat(value) : value
    if (isNaN(value)) {
        return defaultValue
    } else {
        const locale =
            decimalSeparator === ',' || (decimalSeparator === undefined && thousandsSeparator === '.')
                ? 'de-DE'
                : 'en-US'
        const useGrouping = thousandsSeparator === '' ? false : true
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping }
        const formattedNumber = new Intl.NumberFormat(locale, options).format(value)
        return formattedNumber
    }
}

/**
 * Formatea un valor con el formato especificado.
 * @param {number | string} value Valor a formatear.
 * @param {Intl.NumberFormatOptions} format Opciones de formato para el valor.
 * @returns {string} Valor formateado.
 */
export function formatNumber(value, format = {}) {
    value = typeof value === 'string' ? parseFloat(value) : value
    const { locale, ...options } = format
    return new Intl.NumberFormat(locale || 'en-US', options || {}).format(value)
}
