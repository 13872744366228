import { forwardRef, useMemo } from 'react'
import { propFromName } from '../utils'
import { BaseOption, BaseSelect } from '../base/BaseSelect'
export const Select = forwardRef(
    (
        {
            value,
            options,
            optionLabel = 'label',
            optionValue = 'value',
            optionTemplate,
            placeholder = 'Seleccionar',
            ...props
        },
        ref,
    ) => {
        const { menuOptions, selected } = useMemo(() => {
            const _selected = {}
            const _options = options.reduce((carry, option, index) => {
                // Obtener la key de la opción
                const key = getOptionProp(option, optionValue) || `option-${index}`
                // Verificar si la opción esta seleccionada
                const _isSelected = isSelected(false, option, value, optionValue)
                if (_isSelected) _selected[key] = option
                carry.push({
                    index,
                    key,
                    data: option,
                    selected: _isSelected,
                    value: getOptionProp(option, optionValue),
                })
                return carry
            }, [])
            return { menuOptions: _options, selected: Object.keys(_selected)[0] ?? '' }
        }, [value, options, optionValue])
        const invalidValue = value && !selected
        return (
            <BaseSelect {...props} value={invalidValue ? '' : value} ref={ref}>
                {invalidValue ? (
                    <BaseOption value=''>Invalido</BaseOption>
                ) : (
                    placeholder && <BaseOption value=''>{placeholder}</BaseOption>
                )}
                {menuOptions.map((option) => (
                    <BaseOption key={option.key} value={option.value}>
                        {typeof optionTemplate === 'function'
                            ? optionTemplate(option.data)
                            : getOptionProp(option.data, optionLabel)}
                    </BaseOption>
                ))}
            </BaseSelect>
        )
    },
)
const getOptionProp = (option, optionProp) => {
    if (typeof option === 'object' && option !== null) {
        const _prop = propFromName(option, optionProp)
        return toString(_prop)
    } else {
        return String(option ?? '')
    }
}
const toString = (data) => {
    if (data === null || data === undefined) {
        return ''
    }
    if (typeof data === 'object') {
        let str = data.toString()
        if (str === '[object Object]') {
            str = JSON.stringify(data)
        }
        return str
    }
    return String(data)
}
const isSelected = (valueAsOption, option, value, optionValue) => {
    const selected = Array.isArray(value) ? value : value ? [value] : []
    if (valueAsOption) {
        return selected.some((s) => toString(s) === toString(option))
    } else {
        return selected.some((s) => toString(s) === getOptionProp(option, optionValue))
    }
}
