import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { Modal } from 'modules/modular/Modal'
import { PickList } from 'modules/modular/PickList'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'

export default function ModalAssignRoles({ userId, show, onHide, onCloseModal, onSuccess }) {
    const { showHttpToast } = useHttpToast()
    const [submitting, setSubmitting] = useState(false)
    const [source, setSource] = useState([])
    const [target, setTarget] = useState([])

    useEffect(() => {
        const loadRoles = async () => {
            const { status, data } = await ContabilidadServices.role.getToAssign(userId)
            if (status === 200) {
                setSource(data.data.availableRoles)
                setTarget(data.data.userRoles)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        if (show && userId) {
            loadRoles()
        }
    }, [userId])

    const clearData = () => {
        source.length > 0 && setSource([])
        target.length > 0 && setTarget([])
    }
    const handleCloseModal = () => {
        clearData()
        onCloseModal?.()
    }

    const handleChange = (values) => {
        setSource(values.source)
        setTarget(values.target)
    }
    const handleSubmit = async () => {
        if (userId) {
            setSubmitting(true)
            const bodyParams = { roles: target.map((el) => el.name) }
            const { status, data } = await ContabilidadServices.role.batchUpdate(userId, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                onHide()
                onSuccess?.()
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            onExited={handleCloseModal}
            style={{ width: '50rem' }}
            closeOnBackdrop={false}
            keyboard={false}
        >
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>Editar roles</div>
            <div className='p-3'>
                <PickList
                    sourceHeader='Roles disponibles'
                    source={source}
                    targetHeader='Roles asignados'
                    target={target}
                    optionLabel='name'
                    style={{ minHeight: '15rem' }}
                    onChange={handleChange}
                />
            </div>
            <div className='px-3 py-2 border-top text-end'>
                <Button variant='outline-primary' className='me-2' onClick={onHide} loading={submitting}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} loading={submitting}>
                    Guardar
                </Button>
            </div>
        </Modal>
    )
}
