import { Modal } from 'modules/modular/Modal'
import FormUsuario from './FormUsuario'

export default function ModalFormUsuario({ show, onHide, onExited, id, onSuccessCreate, onSuccessUpdate }) {
    return (
        <Modal
            show={show}
            onExited={onExited}
            style={{ width: '45rem' }}
            closeOnBackdrop={false}
            keyboard={false}
        >
            <div className='px-3 py-2 border-bottom fs-4 fw-medium'>{id ? 'Editar' : 'Crear'} Usuario</div>
            <FormUsuario
                id={id}
                onCancel={onHide}
                onSuccessCreate={onSuccessCreate}
                onSuccessUpdate={onSuccessUpdate}
            />
        </Modal>
    )
}
