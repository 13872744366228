import Header from 'Component/Header'
import { objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { empty } from 'modules/modular/utils'
import { useMemo, useRef, useState } from 'react'
import RecaudacionesServices from 'services/recaudaciones.service'

export default function UpdatePersona() {
    const lastSearchValue = useRef('')
    const { showHttpToast } = useHttpToast()
    const [searchValue, setSearchValue] = useState('')
    const [searching, setSearching] = useState(false)
    const [personas, setPersonas] = useState([])
    const [selected, setSelected] = useState(null)
    const [errors, setErrors] = useState({})

    const columns = useMemo(
        () => [
            {
                id: 'selector',
                selector: true,
            },
            {
                field: 'documento',
                header: 'Documento',
                cellClassName: 'text-center',
            },
            {
                field: 'complemento_documento',
                header: 'Doc. Complemento',
                default: '-',
                cellClassName: 'text-center',
            },
            {
                field: 'apellido_1',
                header: 'Apellido 1',
            },
            {
                field: 'apellido_2',
                header: 'Apellido 2',
                default: '-',
                cellClassName: ({ value }) => empty(value) && 'text-center',
            },
            {
                field: 'apellido_3',
                header: 'Apellido 3',
                default: '-',
                cellClassName: ({ value }) => empty(value) && 'text-center',
            },
            {
                field: 'nombre_1',
                header: 'Nombre 1',
            },
            {
                field: 'nombre_2',
                header: 'nombre 2',
                default: '-',
                cellClassName: ({ value }) => empty(value) && 'text-center',
            },
        ],
        [],
    )

    const validatePersona = (values) => {
        const _errors = {}
        if (empty(values.apellido_1)) {
            _errors.apellido_1 = 'Requerido'
        }
        if (empty(values.nombre_1)) {
            _errors.nombre_1 = 'Requerido'
        }
        setErrors(_errors)
        return Object.keys(_errors).length
    }
    const handleChange = (e) => {
        const { name } = e.target
        setSelected((prev) => ({ ...prev, [name]: e.target.value.toUpperCase() }))
        errors[name] && setErrors((prev) => ({ ...prev, [name]: '' }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validatePersona(selected)) {
            const bodyParams = {
                apellido_1: selected.apellido_1.trim(),
                apellido_2: selected.apellido_2?.trim() || null,
                apellido_3: selected.apellido_3?.trim() || null,
                nombre_1: selected.nombre_1.trim(),
                nombre_2: selected.nombre_2?.trim() || null,
            }
            const { status, data } = await RecaudacionesServices.persona.update(selected.id, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                setSelected(null)
                searchPersonas(lastSearchValue.current)
            }
        }
    }
    const handleSearchPersonas = async () => {
        setSearching(true)
        await searchPersonas(searchValue)
        setSearching(false)
    }
    const searchPersonas = async (documento) => {
        if (!empty(documento)) {
            let filter = { documento }
            if (/^[a-zA-Z0-9]+-[a-zA-Z0-9]{2}$/.test(documento)) {
                const [_documento, complemento] = documento.split('-')
                filter = {
                    or: [
                        { documento },
                        {
                            documento: _documento,
                            complemento_documento: complemento,
                        },
                    ],
                }
            }

            const params = objectToQueryString({ filter, all: true })
            const { status, data } = await RecaudacionesServices.persona.list(params)
            if (status === 200) {
                lastSearchValue.current = documento
                selected && setSelected(null)
                setPersonas(data.data)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
    }

    return (
        <div>
            <Header title='MODIFICAR DATOS DE PERSONA' />
            <div className='d-flex justify-content-center'>
                <div className='container shadow-sm p-4 bg-body rounded'>
                    <div className='d-flex gap-2 align-items-center justify-content-center py-3'>
                        <span className='fw-medium'>Documento:</span>{' '}
                        <Input
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
                            onKeyUp={(e) => e.key === 'Enter' && handleSearchPersonas()}
                            className='w-auto'
                        />{' '}
                        <Button
                            onClick={handleSearchPersonas}
                            startIcon={<span className='bi-search' />}
                            loading={searching}
                        />
                    </div>
                    <div className='fs-5 mb-2'>Personas</div>
                    <DataTable
                        columns={columns}
                        values={personas}
                        defaultAlignHeader='center'
                        className='mb-4'
                        selection={selected}
                        selectionMode='single'
                        selectionOn='row'
                        onSelectionChange={(selection) => setSelected(selection)}
                    />
                    <div className='fs-5 mb-3'>Modificar</div>
                    <form onSubmit={handleSubmit}>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Documento:
                                </div>
                            }
                            inline
                        >
                            <div className='form-control bg-body-secondary' style={{ minHeight: '2.5rem' }}>
                                {selected?.documento}
                            </div>
                        </FieldGroup>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Apellido 1: <span className='text-danger'>*</span>
                                </div>
                            }
                            inline
                        >
                            <Input
                                name='apellido_1'
                                value={selected?.apellido_1 ?? ''}
                                onChange={handleChange}
                                disabled={!selected}
                                isInvalid={errors.apellido_1}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Apellido 2:
                                </div>
                            }
                            inline
                        >
                            <Input
                                name='apellido_2'
                                value={selected?.apellido_2 ?? ''}
                                onChange={handleChange}
                                disabled={!selected}
                                isInvalid={errors.apellido_2}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Apellido 3:
                                </div>
                            }
                            inline
                        >
                            <Input
                                name='apellido_3'
                                value={selected?.apellido_3 ?? ''}
                                onChange={handleChange}
                                disabled={!selected}
                                isInvalid={errors.apellido_3}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Nombre 1: <span className='text-danger'>*</span>
                                </div>
                            }
                            inline
                        >
                            <Input
                                name='nombre_1'
                                value={selected?.nombre_1 ?? ''}
                                onChange={handleChange}
                                disabled={!selected}
                                isInvalid={errors.nombre_1}
                            />
                        </FieldGroup>
                        <FieldGroup
                            label={
                                <div className='fw-medium' style={{ width: '6rem' }}>
                                    Nombre 2:
                                </div>
                            }
                            inline
                            className='mb-2'
                        >
                            <Input
                                name='nombre_2'
                                value={selected?.nombre_2 ?? ''}
                                onChange={handleChange}
                                disabled={!selected}
                                isInvalid={errors.nombre_2}
                            />
                        </FieldGroup>
                        <div className='mb-2 text-center fst-italic' style={{ fontSize: '.9rem' }}>
                            Los campos marcados con <span className='text-danger'>*</span> son obligatorios.
                        </div>
                        <div className='bg-blue text-center rounded p-3'>
                            <Button type='submit' startIcon={<span className='bi-floppy' />} disabled={!selected}>
                                Guardar
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
