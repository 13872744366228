import { useMemo } from 'react'
import { classNames } from '../utils'
import ChevronDoubleLeft from '../icons/ChevronDoubleLeft'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'
import ChevronDoubleRight from '../icons/ChevronDoubleRight'
export function Pagination({
    children,
    currentPage = 1,
    totalItems = 0,
    pageSize = 10,
    pageSizeOptions,
    onPageChange,
    onPageSizeChange,
    pagesToShow = 0,
    responsive,
    className,
    style,
    extraOptions,
}) {
    const pagination = useMemo(() => {
        const cantPages = pagesToShow > 0 ? (pagesToShow % 2 === 0 ? pagesToShow + 1 : pagesToShow) : 0
        const pps = Math.floor(cantPages / 2) // Cantidad de números de pagina a mostrar por lado de la pagina activa
        const ps = pageSize > 0 ? pageSize : 10 // tamaño de pagina
        const ti = totalItems >= 0 ? totalItems : 0 // total de items
        const tp = ti > 0 ? Math.ceil(ti / ps) : 1 // total de paginas
        const cp = currentPage > 0 ? currentPage : 1 // pagina actual
        let ini = 0
        let end = 0
        if (cantPages > 0) {
            if (cp - pps > 0) {
                if (cp + pps <= tp) {
                    ini = cp - pps
                    end = cp + pps
                } else {
                    ini = tp - cantPages + 1 || 1
                    end = tp
                }
            } else {
                ini = 1
                end = cantPages <= tp ? cantPages : tp
            }
        }
        const numberPages = cantPages > 0 ? Array.from({ length: end - ini + 1 }, (_, i) => ini + i) : []
        return {
            currentPage: cp,
            pageSize: ps,
            totalItems: ti,
            totalPages: tp,
            offset: (cp - 1) * ps,
            limit: ps,
            numberPages,
        }
    }, [currentPage, totalItems, pageSize, pagesToShow])
    const handlePageChange = (page) => {
        onPageChange?.(page)
    }
    const handleInputPageChange = (e) => {
        const value = Number(e.target.value)
        if (value > 0 && value <= pagination.totalPages) {
            handlePageChange(value)
        }
    }
    const handlePageSizeChange = (pageSize) => {
        onPageSizeChange?.(pageSize)
    }
    const handleSelectPageSizeChange = (e) => {
        const value = Number(e.target.value)
        if (value > 0) {
            handlePageSizeChange(value)
        }
    }
    /** Verifica si hay una página siguiente. */
    const hasNextPage = () => {
        return pagination.currentPage < pagination.totalPages
    }
    /** Cambia a la página siguiente. */
    const goNextPage = () => {
        handlePageChange(pagination.currentPage + 1)
    }
    /** Cambia a la última página. */
    const goLastPage = () => {
        if (pagination.currentPage < pagination.totalPages) {
            handlePageChange(pagination.totalPages)
        }
    }
    /** Verifica si hay una página anterior. */
    const hasPreviousPage = () => {
        return pagination.currentPage > 1
    }
    /** Cambia a la página anterior. */
    const goPreviousPage = () => {
        handlePageChange(pagination.currentPage - 1)
    }
    /** Cambia a la primera página. */
    const goFistPage = () => {
        if (pagination.currentPage > 1) {
            handlePageChange(1)
        }
    }
    const pageSizeSelectElement = pageSizeOptions && (
        <select className='mod-pps-selector' value={pageSize} onChange={handleSelectPageSizeChange}>
            {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
    const pageInputElement = (
        <input
            className='mod-pp-input'
            value={pagination.currentPage}
            onChange={handleInputPageChange}
            onFocus={(e) => {
                e.target.select()
            }}
        />
    )
    const pageButtonElements = (
        <>
            <button className='mod-pp-button' onClick={goFistPage} disabled={!hasPreviousPage()}>
                <ChevronDoubleLeft />
            </button>
            <button className='mod-pp-button' onClick={goPreviousPage} disabled={!hasPreviousPage()}>
                <ChevronLeft />
            </button>
            {pagination.numberPages.map((page) => (
                <button
                    key={page}
                    className={classNames(['mod-pp-button', currentPage === page && 'active'])}
                    onClick={currentPage !== page ? () => handlePageChange(page) : undefined}
                >
                    {page}
                </button>
            ))}
            <button className='mod-pp-button' onClick={goNextPage} disabled={!hasNextPage()}>
                <ChevronRight />
            </button>
            <button className='mod-pp-button' onClick={goLastPage} disabled={!hasNextPage()}>
                <ChevronDoubleRight />
            </button>
        </>
    )
    return (
        <div
            className={classNames(
                'modular-pagination',
                responsive ? (typeof responsive === 'string' ? `responsive-${responsive}` : 'responsive') : undefined,
                className,
            )}
            style={style}
        >
            {children ? (
                children({
                    ...pagination,
                    hasNextPage,
                    goNextPage,
                    goLastPage,
                    hasPreviousPage,
                    goPreviousPage,
                    goFistPage,
                    handlePageChange,
                    handlePageSizeChange,
                    pageInputElement,
                    pageSizeSelectElement,
                    pageButtonElements,
                })
            ) : (
                <div className='mod-p-wrapper'>
                    <div className='mod-p-extra'>{extraOptions}</div>
                    {pageSizeSelectElement && (
                        <div className='mod-p-controls'>
                            Filas por página
                            {pageSizeSelectElement}
                        </div>
                    )}
                    <div className='mod-p-controls'>
                        Página {pageInputElement} de {pagination.totalPages}
                    </div>
                    <div className='mod-p-controls'>{pageButtonElements}</div>
                </div>
            )}
        </div>
    )
}
